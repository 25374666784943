<template>
  <div>
    <v-app-bar color="red accent-4" dense dark>
      <v-toolbar-title>Ticket</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="$emit('limpiarTicket'); trash()" icon v-bind="attrs" v-on="on">
           <i style="font-size: 1.5rem" class="el-icon-delete-solid"></i>
          </v-btn>
          <v-btn @click="$emit('editarTicket'); beep()" icon v-bind="attrs" v-on="on">
           <i style="font-size: 1.5rem" class="el-icon-edit"></i>
          </v-btn>
        </template>
      </v-menu>
    </v-app-bar>
    <div class="contenedorticket">
      <div :class="[{negativo: ticket.precio.toFixed(2) < 0}]" v-for="ticket in ticketMostrar" :key="ticket">
        <span>{{ ticket.nombre }} <em>x{{ ticket.cantidad }}</em></span> <span class="precio">{{
          ticket.precio.toFixed(2) }}</span>
      </div>
    </div>
    <span class="totalticket"><b>Total:</b><b>{{ totalPagar.toFixed(2) }}€</b></span>
    <span @click="$emit('cobrar', totalPagar.toFixed(2))" :class="`botoncobrar ${ticket.length == 0 ? 'disabled' : ''}`">
      Cobrar
    </span>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    ticket: {
      type: Array,
      required: true,
    },
    articulos: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ticketMostrar() {
      let salida = {};
      let contador = 0;
      this.ticket.forEach((articulo) => {
        if(articulo.cantidad) {
          salida['art' + articulo.id + '' + contador] = {
            id: articulo.id,
            nombre: articulo.nombre,
            cantidad: articulo.cantidad,
            precio: 0,
          };
          salida['art' + articulo.id + '' + contador].precio = this.calcularPrecioTotal(articulo, salida['art' + articulo.id + '' + contador].cantidad)
          contador ++;
        } else {
        if (salida['art' + articulo.id]) {
          if(articulo.cantidad) {
          salida['art' + articulo.id].cantidad += articulo.cantidad;
          } else {
            salida['art' + articulo.id].cantidad++;
          }
          salida['art' + articulo.id].precio = this.calcularPrecioTotal(articulo, salida['art' + articulo.id].cantidad)
        } else {
          salida['art' + articulo.id] = {
            id: articulo.id,
            nombre: articulo.nombre,
            cantidad: articulo.cantidad ?? 1,
            precio: 0,
          };
        }
        salida['art' + articulo.id].precio = this.calcularPrecioTotal(articulo, salida['art' + articulo.id].cantidad)
      }
      });
      return salida;
    },
    totalPagar() {
      let total = 0;
      Object.keys(this.ticketMostrar).forEach(i => {
        total += this.ticketMostrar[i]['precio'];
      })
      return total;
    }
  },
  data: () => ({
    total: 0,
  }),
  methods: {
    beep() {
          const audio = new Audio(require('@/assets/beep.mp3'));
          audio.play();
        },
    trash() {
          const audio = new Audio(require('@/assets/trash.mp3'));
          audio.play();
        },
    calcularPrecioTotal(articulo, cantidad) {
      let precioTotal = 0;
      let unidadesRestantes = cantidad;
      console.log(articulo);
      Object.keys(articulo.lotes).forEach(lotee => {
        console.log(lotee);
        let lote = articulo.lotes[lotee];
        console.log(lote);
        let cantidadNecesaria = Math.trunc(unidadesRestantes / lote['cantidad']);
        console.log("Iniciamos cantidad necesaria para " + lote['cantidad']);
        console.log(cantidadNecesaria)
        if (cantidadNecesaria > 0) {
          precioTotal += (lote['precio'] * cantidadNecesaria);
          unidadesRestantes -= cantidadNecesaria * lote['cantidad'];
        }
      })
      return precioTotal;
    },
  }
}
</script>
<style lang="scss" scoped>
.contenedorticket {
  height: calc(80vh - 48px);
  padding: 10px;
  overflow: auto;

  .negativo {
    background:#fc5c65;
    color:white;

    em {
      color: rgb(221, 221, 221);
      font-style: normal;
    }
  }

  div {
    display: flex;
    justify-content: space-between;
    background: white;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid lightgray;
    font-size: 1.2rem;
    margin-bottom: 5px;
  }

  span {
    margin-right: 5px;
    em {
      color: gray;
      font-style: normal;
    }
  }

  &.precio {
    text-align: right;
  }
}

.totalticket {
  height: calc(8vh - 20px);
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid lightgray;
  margin: 10px;
  align-items: center;
  font-size: 2rem;
}

.botoncobrar {
  height: calc(13vh - 20px);
  background: #27ae60;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  font-size: 1.2rem;
  margin: 10px;
  border-radius: 5px;
  transition: 0.5s;

  &.disabled {
    background: #27ae5f5d;
  }
}

* {
  touch-action: manipulation;
}
</style>