<template>
  <v-row no-gutters>
    <v-overlay id="editarticket" style="background: white; opacity: 1; color:black; padding: 0px 20px;" v-if="drawer"
      class="align-center justify-center">
      <h2 style="height: 30px;">Editar artículos</h2>
      <div style="height: calc(100vh - 120px); overflow-y: auto; margin: 10px 0px; width: 100%; font-size: 1.3rem;">
        <div v-for="(art) in dashboard" :key="art" style="margin: 10px 0px; border: 1px solid darkgray; padding: 5px;">
          <div style="display:flex">
            <el-input v-model="art.id" style="width: 10%"></el-input><el-input v-model="art.nombre"></el-input>
          </div>
          <table style="margin-top: 10px">
            <thead>
              <tr>
                <th>
                  Precio
                </th>
                <th>
                  Tipo
                </th>
                <th v-if="art.tipo == 'articulogranel'">
                  Selector
                </th>
              </tr>
            </thead>
            <tbody>
              <td>
                <el-input v-model="art.lotes[0].precio"></el-input>
              </td>
              <td>
                <el-select v-model="art.tipo">
                  <el-option label="Artículo" value="articulo"></el-option>
                  <el-option label="Artículo a granel" value="articulogranel"></el-option>
                </el-select>
              </td>
              <td v-if="art.tipo == 'articulogranel'">
                <el-select v-model="art.selector">
                  <el-option label="Peso" value="peso"></el-option>
                  <el-option label="Precio" value="precio"></el-option>
                  <el-option label="No definido" value=""></el-option>
                </el-select>
              </td>
            </tbody>
          </table>
        </div>
      </div>
      <div style="height:40px; display:flex;">
        <el-button type="warning" style="width: 100%;" @click="drawer = false; beep('error');">Guardar y
          salir</el-button>
        <el-button type="danger" style="width: 100%;"
          @click="drawer = false; beep('error'); vaciarAlmacenamiento()">Borrar almacenamiento</el-button>
        <el-button type="success" style="width: 100%;" @click="drawer = false; beep('error'); guardarLista()">Guardar
          almacenamiento</el-button>
      </div>
    </v-overlay>
    <v-overlay id="editarticket" style="background: white; opacity: 1; color:black; padding: 0px 20px;" v-if="drawerexperto"
      class="align-center justify-center">
      <h2 style="height: 30px;">MODO EXPERTO</h2>
      <div style="height: calc(100vh - 120px); overflow-y: auto; margin: 10px 0px; width: 100%; font-size: 1.3rem;">
        <el-input type="textarea"  :autosize="{ minRows: 2}" v-model="dashboardjson"></el-input>
      </div>
      <div style="height:40px; display:flex;">
        <el-button type="warning" style="width: 100%;" @click="drawerexperto = false; dashboard = JSON.parse(dashboardjson); beep('error');">Guardar y
          salir</el-button>
        <el-button type="danger" style="width: 100%;"
          @click="drawerexperto = false; beep('error'); vaciarAlmacenamiento()">Borrar almacenamiento</el-button>
        <el-button type="success" style="width: 100%;" @click="drawerexperto = false; beep('error'); dashboard = JSON.parse(dashboardjson); guardarLista()">Guardar
          almacenamiento</el-button>
      </div>
    </v-overlay>
    <v-overlay style="background: white; opacity: 1; color:black;" v-if="drawergranel"
      class="align-center justify-center">
      <h2 v-if="granelseleccion.selector == 'precio'">Introduce precio</h2>
      <h2 v-if="granelseleccion.selector == 'peso'">Pesa el artículo e introduce el peso</h2>
      <div class="container">
        <h1 v-if="granelseleccion.selector == 'peso'" style="font-size: 8rem; color: black; max-width: 100%"
          class="weight">{{ pesoMostrar.toFixed(3) }}</h1>
        <h1 v-if="granelseleccion.selector == 'precio'" style="font-size: 8rem; color: black; max-width: 100%"
          class="weight">{{ pesoMostrar.toFixed(2) }} €</h1>
      </div>
      <div class="contenedornumeros">
        <div class="grid-item" @click="agregarNumero('1')">1</div>
        <div class="grid-item" @click="agregarNumero('2')">2</div>
        <div class="grid-item" @click="agregarNumero('3')">3</div>
        <div style="background: #f39c12; color: white;" class="grid-item" @click="borrarNumero()">Borrar</div>
        <div class="grid-item" @click="agregarNumero('4')">4</div>
        <div class="grid-item" @click="agregarNumero('5')">5</div>
        <div class="grid-item" @click="agregarNumero('6')">6</div>
        <div style="background: #2980b9; color: white;" class="grid-item" @click="limpiar()">Limpiar</div>
        <div class="grid-item" @click="agregarNumero('7')">7</div>
        <div class="grid-item" @click="agregarNumero('8')">8</div>
        <div class="grid-item" @click="agregarNumero('9')">9</div>
        <div style="background: #c0392b; color: white;" class="grid-item"
          @click="peso = '0'; drawergranel = false; beep('error')">
          Cancelar</div>
        <div class="grid-item"></div> <!-- Espacio en blanco para alinear los botones -->
        <div class="grid-item" @click="agregarNumero('0')">0</div>
        <div class="grid-item"></div> <!-- Espacio en blanco para alinear los botones -->
        <div style="background: #27ae60; color: white;" class="grid-item" @click="confirmar(); beep('beep')">Confirmar
        </div>
      </div>
    </v-overlay>
    <v-overlay id="editarticket" style="background: white; opacity: 1; color:black; padding: 0px 20px;"
      v-if="drawereditarticket" class="align-center justify-center">
      <h2 style="height: 30px;">Editar ticket</h2>
      <div style="height: calc(100vh - 120px); overflow-y: auto; margin: 10px 0px; width: 100%; font-size: 1.3rem;">
        <div v-for="(art, index) in ticket" :key="art" style="margin: 10px 0px;">
          <el-button style="margin-right: 10px" @click="ticket.splice(index, 1); beep('trash')" type="danger"
            icon="el-icon-delete"></el-button>
          <span style="color:darkgray" v-if="art.cantidad">{{ calcularPrecio(art) }}€</span>
          <span style="color:darkgray" v-if="!art.cantidad">{{ art.lotes[0].precio.toFixed(2) }}€</span>
          {{ art.nombre }}
        </div>
      </div>
      <div style="height:30px">
        <el-button type="success" style="width: 100%;"
          @click="drawereditarticket = false; beep('error')">Finalizar</el-button>
      </div>
    </v-overlay>
    <v-overlay id="cobrar" style="background: white; opacity: 1; width: 100%" v-if="drawercobrar"
      class="align-center justify-center">
      <div
        style="height: 100vh; width: 100%; background: white; display:flex; flex-direction:column; justify-content:space-between; color: black; padding: 100px 0px 50px 0px;">
        <div style="display:flex; width: 100%; justify-content: space-evenly;">
          <div>
            <h2>Importe a cobrar</h2>
            <h1 style="font-size: 5rem;">{{ totalPagar }}€</h1>
          </div>
          <div>
            <h2>Importe pagado</h2>
            <h1 style="font-size: 5rem;">{{ importePagado.toFixed(2) }}€</h1>
          </div>
          <div v-if="totalPagar > importePagado">
            <h2>Importe pendiente</h2>
            <h1 style="font-size: 5rem; color: #d63031;">{{ (totalPagar - importePagado).toFixed(2) }}€</h1>
          </div>
          <div v-if="totalPagar < importePagado">
            <h2>Importe a devolver</h2>
            <h1 style="font-size: 5rem; color:#00b894;">{{ (totalPagar - importePagado).toFixed(2).split('-')[1] }}€
            </h1>
          </div>
          <div v-if="totalPagar == importePagado">
            <h2>Estado</h2>
            <h1 style="font-size: 5rem; color:#00b894">PAGADO</h1>
          </div>
        </div>
        <div class="contenedordinero" style="display: flex; justify-content: space-evenly">
          <img @click="importePagado += 50; beep('beep')" src="@/assets/dinero/50.png" width="250px">
          <img @click="importePagado += 20; beep('beep')" src="@/assets/dinero/20.png" width="250px">
          <img @click="importePagado += 10; beep('beep')" src="@/assets/dinero/10.png" width="250px">
          <img @click="importePagado += 5; beep('beep')" src="@/assets/dinero/5.png" width="250px">
        </div>
        <div class="contenedordinero" style="display: flex; justify-content: space-evenly; align-items: center;">
          <img @click="importePagado += 2; beep('beep')" src="@/assets/dinero/2.png" width="100px">
          <img @click="importePagado += 1; beep('beep')" src="@/assets/dinero/1.png" width="90px" height="90px">
          <img @click="importePagado += 0.5; beep('beep')" src="@/assets/dinero/50c.png" width="90px" height="90px">
          <img @click="importePagado += 0.2; beep('beep')" src="@/assets/dinero/20c.png" width="80px" height="80px">
          <img @click="importePagado += 0.1; beep('beep')" src="@/assets/dinero/10c.png" width="70px" height="70px">
          <img @click="importePagado += 0.05; beep('beep')" src="@/assets/dinero/5c.png" width="90px" height="90px">
          <img @click="importePagado += 0.02; beep('beep')" src="@/assets/dinero/2c.png" width="80px" height="80px">
          <img @click="importePagado += 0.01; beep('beep')" src="@/assets/dinero/1c.png" width="70px" height="70px">
        </div>
        <div style="display: flex; justify-content: space-between;">
          <button @click="drawercobrar = false; importePagado = 0; beep('error')"
            style="width: 100%; padding: 10px; font-size: 2rem; background: #c0392b; color:white; margin: 10px;">Volver</button>
          <button @click="importePagado = 0; beep('error')"
            style="width: 100%; padding: 10px; font-size: 2rem; background: #f39c12; color:white; margin: 10px;">Corregir</button>
          <button @click="drawercobrar = false; ticket = []; importePagado = 0; beep('finalizar')"
            style="width: 100%; padding: 10px; font-size: 2rem; background:#27ae60; color:white; margin: 10px;">Finalizar</button>
        </div>
      </div>
    </v-overlay>
    <v-col v-if="mode == 'dashboard' || mode == 'categoria'" id="colizquierda" :md="9">
      <div v-if="mode == 'dashboard'">
        <v-app-bar color="deep-purple accent-4" dense dark>
          <v-app-bar-nav-icon @click="drawer = true; beep('beep')"></v-app-bar-nav-icon>
          <v-app-bar-nav-icon @click="drawerexperto = true; beep('beep')"></v-app-bar-nav-icon>
          <v-toolbar-title>Inicio</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        <div class="grid-container">
          <div v-for="articulo in dashboard" :key="articulo.nombre + articulo.id"
            :style="`background-color: ${articulo.color}; ${articulo.imagen != 'null' ? 'background-image: url(' + articulo.imagen + '); background-size:cover; background-position: 50%' : '};'}`"
            @click="recogerClick(articulo)" class="grid-item">
            <div class="content">
              <p>{{ articulo.nombre }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="mode == 'categoria'">
        <v-app-bar color="deep-purple accent-4" dense dark>
          <v-btn class="ma-2" text icon color="red lighten-2" @click="mode = 'dashboard'">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>{{ categoria.nombre }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-app-bar>
        <div class="grid-container">
          <div v-for="articulo in filtroCategoria" :key="articulo.nombre + articulo.id"
            :style="`background-color: ${articulo.color}; ${articulo.imagen != 'null' ? 'background-image: url(' + articulo.imagen + '); background-size:cover;' : '};'}`"
            @click="recogerClick(articulo)" class="grid-item">
            <div class="content">
              <p>{{ articulo.nombre }}</p>
            </div>
          </div>
        </div>
      </div>
    </v-col>
    <v-col v-if="mode == 'dashboard' || mode == 'categoria'" id="colderecha" :md="3">
      <HelloWorld :ticket="ticket" :articulos="articulos" @limpiarTicket="ticket = []" @cobrar="cobrar"
        @editarTicket="drawereditarticket = true" />
    </v-col>
  </v-row>
</template>

<script>
import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',
  components: {
    HelloWorld,
  },
  mounted() {
    document.addEventListener('gesturestart', function (e) {
      e.preventDefault();
    });
    this.fetchDashboard();
  },
  data: () => ({
    mode: 'dashboard',
    categoria: 0,
    ticket: [],
    dashboard: [],
    articulos: [],
    categorias: [],
    drawer: false,
    draweraddlote: false,
    articuloFicha: '',
    selectedCategory: '',
    nuevoArticulo: {
      nombre: '',
      lotes: [],
    },
    granelseleccion: {},
    drawergranel: false,
    peso: '0',
    drawercobrar: false,
    drawereditarticket: false,
    drawerexperto: false,
    totalPagar: 0,
    importePagado: 0,
    dashboardjson: '',
  }),
  computed: {
    filtroCategoria() {
      return this.articulos.filter(articulo => articulo.categoria == this.categoria.id);
    },
    categoriasFiltradas() {
      let nombresCategorias = Object.keys(this.categorias).map(key => this.categorias[key].nombre);
      return nombresCategorias;
    },
    pesoMostrar() {
      if (this.granelseleccion.selector == 'peso') {
        return this.peso / 1000
      }
      return this.peso / 100
    }
  },
  methods: {
    vaciarAlmacenamiento() {
      localStorage.removeItem('precios');
      this.fetchDashboard();
    },
    guardarLista() {
      localStorage.setItem('precios', JSON.stringify(this.dashboard));
    },
    cobrar(e) {
      this.drawercobrar = true;
      console.log(e);
      this.totalPagar = e;
    },
    beep(sonido) {
      const audio = new Audio(require('@/assets/' + sonido + '.mp3'));
      audio.play();
    },
    async fetchDashboard() {
      if (localStorage.getItem('precios')) {
        this.dashboard = JSON.parse(localStorage.getItem('precios'));
      } else {
        this.dashboard = [
          {
            id: 1,
            tipo: 'articulo',
            nombre: 'Pan hogaza y barra',
            imagen: require('@/assets/img/hogaza.png'),
            lotes: [
              {
                cantidad: 1,
                precio: 4
              }
            ]
          },
          {
            id: 2,
            tipo: 'articulo',
            nombre: 'Pan de maíz con cúrcuma',
            imagen: require('@/assets/img/curcuma.webp'),
            lotes: [
              {
                cantidad: 1,
                precio: 5
              }
            ]
          },
          {
            id: 3,
            tipo: 'articulo',
            nombre: 'Pan de maíz con pipas',
            imagen: require('@/assets/img/maizpipas.png'),
            lotes: [
              {
                cantidad: 1,
                precio: 15
              }
            ]
          },
          {
            id: 11,
            tipo: 'articulo',
            nombre: 'Pan de maíz con pipas MEDIO',
            imagen: require('@/assets/img/maizpipas.png'),
            lotes: [
              {
                cantidad: 1,
                precio: 8
              }
            ]
          },
          {
            id: 4,
            tipo: 'articulo',
            nombre: 'Pan de centeno con multicereales',
            imagen: require('@/assets/img/multicereales.png'),
            lotes: [
              {
                cantidad: 1,
                precio: 5
              }
            ]
          },
          {
            id: 5,
            tipo: 'articulogranel',
            nombre: 'Pan de pasas y nueces (granel)',
            imagen: require('@/assets/img/pasasnueces.png'),
            selector: 'peso',
            lotes: [
              {
                cantidad: 1,
                precio: 0.01
              }
            ]
          },
          {
            id: 6,
            tipo: 'articulo',
            nombre: 'Empanada ENTERA',
            imagen: require('@/assets/img/empanada.png'),
            lotes: [
              {
                cantidad: 1,
                precio: 18
              }
            ]
          },
          {
            id: 7,
            tipo: 'articulo',
            nombre: 'Empanada MEDIA',
            imagen: require('@/assets/img/empanada.png'),
            lotes: [
              {
                cantidad: 1,
                precio: 9
              }
            ]
          },
          {
            id: 8,
            tipo: 'articulo',
            nombre: 'Empanada TROZO',
            imagen: require('@/assets/img/empanada.png'),
            lotes: [
              {
                cantidad: 1,
                precio: 3.5
              }
            ]
          },
          {
            id: 9,
            tipo: 'articulo',
            nombre: 'Bollo preñado',
            imagen: require('@/assets/img/preñado.png'),
            lotes: [
              {
                cantidad: 1,
                precio: 2.5
              }
            ]
          },
          {
            id: 10,
            tipo: 'articulogranel',
            nombre: 'Repostería granel',
            imagen: require('@/assets/img/rosquillas.png'),
            selector: 'peso',
            lotes: [
              {
                cantidad: 1,
                precio: 0.015
              }
            ]
          },
          {
            id: 21,
            tipo: 'articulo',
            nombre: 'Muffin',
            imagen: require('@/assets/img/muffin_chocolate.webp'),
            lotes: [
              {
                cantidad: 1,
                precio: 3
              }
            ]
          },
          {
            id: 12,
            tipo: 'articulo',
            nombre: 'Bollería variada',
            imagen: require('@/assets/img/bolleria.png'),
            lotes: [
              {
                cantidad: 1,
                precio: 4
              }
            ]
          },
          {
            id: 13,
            tipo: 'articulo',
            nombre: 'Palmera',
            imagen: require('@/assets/img/palmera.webp'),
            lotes: [
              {
                cantidad: 1,
                precio: 5
              }
            ]
          },
          {
            id: 14,
            tipo: 'articulo',
            nombre: 'Bizcocho frutas ENTERO',
            imagen: require('@/assets/img/bizcocho.webp'),
            lotes: [
              {
                cantidad: 1,
                precio: 10
              }
            ]
          },
          {
            id: 15,
            tipo: 'articulo',
            nombre: 'Bizcocho frutas MEDIO',
            imagen: require('@/assets/img/bizcocho.webp'),
            lotes: [
              {
                cantidad: 1,
                precio: 5
              }
            ]
          },
          {
            id: 16,
            tipo: 'articulo',
            nombre: 'Tarta ENTERA',
            imagen: require('@/assets/img/tarta.png'),
            lotes: [
              {
                cantidad: 1,
                precio: 15
              }
            ]
          },
          {
            id: 17,
            tipo: 'articulo',
            nombre: 'Tarta MEDIA',
            imagen: require('@/assets/img/tarta.png'),
            lotes: [
              {
                cantidad: 1,
                precio: 8
              }
            ]
          },
          {
            id: 18,
            tipo: 'articulo',
            nombre: 'Tarta RACION',
            imagen: require('@/assets/img/raciontarta.avif'),
            lotes: [
              {
                cantidad: 1,
                precio: 3.5
              }
            ]
          },
          {
            id: 19,
            tipo: 'articulogranel',
            nombre: 'VARIOS',
            selector: 'precio',
            imagen: require('@/assets/img/varios.png'),
            lotes: [
              {
                cantidad: 1,
                precio: 0.01
              }
            ]
          },
          {
            id: 20,
            tipo: 'articulogranel',
            nombre: 'DESCUENTOS',
            selector: 'precio',
            imagen: require('@/assets/img/descuentos.png'),
            lotes: [
              {
                cantidad: 1,
                precio: -0.01
              }
            ]
          },
        ];
      }
      this.dashboardjson = JSON.stringify(this.dashboard, null, "\t")
    },
    recogerClick(articulo) {
      this.beep('beep');
      articulo.tipo == 'articulo' ? this.ticket.push(articulo) : '';
      if (articulo.tipo == 'categoria') {
        this.mode = 'categoria';
        this.categoria = articulo;
      }
      if (articulo.tipo == 'articulogranel') {
        this.granelseleccion = articulo;
        this.articulogranel = JSON.parse(JSON.stringify(articulo));
        this.drawergranel = true;
      }
    },
    agregarNumero(numero) {
      this.beep('click')
      this.peso == '0' ? this.peso = numero : this.peso += numero;
    },
    borrarNumero() {
      this.beep('click')
      if (this.peso.length > 1) {
        this.peso = this.peso.slice(0, -1); // Eliminar el último dígito del peso
      } else {
        this.peso = '0'; // Si solo queda un dígito, establecer el peso a 0.000
      }
    },
    limpiar() {
      this.peso = '0'; // Restablecer el peso a 0.000
    },
    confirmar() {
      this.articulogranel.cantidad = this.peso / 1;
      this.ticket.push(this.articulogranel);
      this.drawergranel = false;
      this.peso = '0';
    },
    calcularPrecio(art) {
      return (art.cantidad / 1 * art.lotes[0].precio).toFixed(2)
    }
  }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

html {
  overflow-y: auto;
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0px;
}

#colizquierda {
  height: 100vh;
  overflow-y: auto;

  .columnacrear {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* Crea 5 columnas de igual ancho */
    grid-gap: 10px;
    /* Espacio entre las columnas */
    margin: 10px
  }

  .grid-item {
    text-align: center;
    height: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.336);
    border-radius: 5px;
    transition: 0.2s;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      /* Fondo negro transparente */
    }

    .content {
      position: relative;
      z-index: 1;
      color: white;
      text-align: center;
      background: rgba(0, 0, 0, 0.336);
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: auto;
      line-height: 15px;
    }

    .content p {
      margin-bottom: 0;
      text-overflow: ellipsis;
      max-height: 50px;
      padding: 10px;
      font-size: 0.9rem;
    }

    &:hover {

      transform: scale(1.1);
    }
  }

  .itemlote {
    display: flex;
    justify-content: space-between;
    background: white;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid lightgray;
  }
}

#colderecha {
  height: 100vh;
  background: rgba(0, 0, 0, 0.13);
}

.contenedornumeros {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-top: 10px;

  .grid-item {

    text-align: center;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: black;
    font-size: 2rem;
    aspect-ratio: 2/1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;

    &:hover {

      transform: scale(1.1);
    }
  }
}

#cobrar,
#editarticket {
  .v-overlay__content {
    width: 100%;
  }
}

.v-overlay__scrim {
  background-color: white !important;
  color: black !important;
}

* {
  touch-action: manipulation;
  user-select: none;
}

.contenedordinero {
  img {
    transition: 0.2s;

    &:hover {
      transform: scale(1.1);
    }
  }
}
</style>